<template>
  <div class=" mx-auto">
    <Modal v-show="showSuccessModal" :heightContainer="'auto'" :widthContainer="'25%'">
      <template v-slot:header>
        <h2 class="text-3xl" style="font-weight:700;font-family:Lato;color:#810042;">Solicitud registrada exitosamente</h2>
      </template>
      <template v-slot:body>
        <div>
          <p style="font-size:18px;font-family:Roboto;font-weight:400;">Hemos enviado el autorizo de descuento para que lo firme al correo proporcionado. 
          Si no lo encuentra, favor de buscar en correo no deseado o en spam. 
          Una vez que lo haya firmado se iniciará el proceso de revisión de solicitud de ingreso, 
          y en breve se le hará llegar su solicitud de ingreso en caso de haber sido aceptado para que la firme</p>
        </div>
      </template>
      <template v-slot:footer>
        <div>
          <div style="display:flex;justify-content:center;">
            <input type="button" class="register cursor-pointer" value="Continuar" :disabled="!timeIntervalFinished"
            v-bind:style="{opacity: timeIntervalFinished ? activeColor : activeColor2 }"
            style="text-decoration:none;
            font-family:Roboto;
            font-size: 16px;
            color:#FFFFFF;
            padding-top:5px;
            padding-bottom:5px;
            padding-left:55px;
            padding-right:55px;
            background-color:#FEB72B;
            border: 0px solid;
            border-width: 3px;border-radius:10px;
            " v-on:click="reedirect()" />
          </div>
        </div>
      </template>
    </Modal>
            <div>
              <a name="validaciones"></a>
                <Loading :isLoading= "this.isLoading" />
                <div style="display: flex;margin:0 auto; margin-bottom:20px;">
                    <div class="div_centrado" style="margin:0 auto;background:white;width:95%;height:100%;margin-top:50px;border-radius:14px; text-align: center;box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);">
                        <div class="flex" style="margin-top:40px;">
                            <div class="w-full">
                                <p class="subTitle">
                                    Aviso de privacidad
                                </p>
                            </div>
                        </div>
                        
                        <div class="flex" style="margin-top:0px;">
                            <div class="w-full">
                                <h4 class="text-xl text-center">Paso 10 de 10</h4>
                            </div>
                        </div>

                        <div class="flex" style="margin-top:40px;">
                            <div class="w-full">
                                <p style="font-family:Roboto;color:#8e8e8e;text-align:center;padding-left: 10px;padding-right: 10px;">
                                    Para concluir con el registro es necesario que lea y acepte los Términos y Condiciones <br/> del Aviso de Privacidad
                                </p>
                            </div>
                        </div>
                        
                        <div class="flex" style="margin-top:70px;padding-right: 10px;">
                            <div class="w-full">
                                <p style="">
                                    <a href="/pdf/FONCABSA - Aviso de Privacidad INTEGRAL - 26092023 VF.pdf" target="_blank">
                                        <input type="button" value="Aviso de privacidad" class="cursor-pointer bg-gray-300" style="text-decoration:none;
                                        font-family:Roboto;
                                        font-size: 16px;
                                        color:#000;
                                        padding-top:5px;
                                        padding-bottom:5px;
                                        padding-left:55px;
                                        padding-right:55px;
                                        border: 0px;
                                        border-width: 3px;border-radius:35px;"/>
                                    </a>
                                </p>
                            </div>
                        </div>
                        
                        <div class="flex" style="margin-top:70px;">
                            <div class="w-full">
                                <p style="font-family:Roboto;color:#8e8e8e;padding-left: 60px;padding-right: 60px;text-align: center;">
                                    He leído y acepto los Términos y Condiciones del Aviso de Privacidad
                                </p>
                            </div>
                        </div>
                        
                        <div class="flex">
                            <div class="w-full">
                                <input type="button" style="border: 2px solid;border-color: #E4E7EB;"
                                v-bind:class="[
                                'hover:bg-gray-200 text-xl py-1 px-20 m-2 rounded-full',
                                acceptConditions ? 'bg-gray-300' : 'bg-white',
                                ]"
                                v-on:click="
                                    (acceptConditions = true)
                                "
                                value="Si"
                            />
                            <input type="button" style="border: 2px solid;border-color: #E4E7EB;"
                                v-bind:class="[
                                'hover:bg-darkGray text-xl py-1 px-20 m-2 rounded-full',
                                acceptConditions === false ? 'bg-gray-300' : 'bg-white',
                                ]"
                                v-on:click="
                                    (acceptConditions = false)
                                "
                                value="No"
                            />
                            </div>
                        </div>

                        <br>
                        <div style="display:flex;justify-content:center">
                          <div>
                            <p>
                              <input type="button" class="next_button" value="Siguiente" :disabled="!acceptConditions"
                              v-bind:style="{opacity: acceptConditions ? activeColor : activeColor2}"
                              v-on:click="generateDocumentSaving()"/>
                            </p>
                          </div>
                        </div>
                        <div style="display:flex;justify-content:center; margin-top:5px">
                          <div>
                            <p>
                              <a href="/cuenta/video/admission_request" style="text-decoration:none;">
                                  <input type="button" class="back_button" value="Atrás"/>
                              </a>
                            </p>
                          </div>
                        </div>
                        <br>

                        
                        
                    
                        <!-- <div class="flex-1 bg-gray-0 px-0 py-0 m-0 md:mt-20" style="">
                            <div class="row">
                                <div class="col col-sm-6">
                                    <p>
                                        <a href="cuenta/beneficiarios" style="text-decoration:none;">
                                            <input type="button" class="regresar" value="Regresar"
                                                style="text-decoration:none;
                                                font-family:Roboto;
                                                font-size: 16px;
                                                color:#FFFFFF;
                                                padding-top:5px;
                                                padding-bottom:5px;
                                                padding-left:55px;
                                                padding-right:55px;
                                                background-color:#FEB72B;
                                                border: 0px solid;
                                                border-width: 3px;border-radius:35px;
                                                float:right;" />
                                        </a>
                                    </p>                                   
                                </div>
                                <div class="col col-sm-6">
                                    <p>
                                        <input type="button" class="register" value="Terminar registro" :disabled="!acceptConditions" 
                                        v-bind:style="{opacity: acceptConditions ? activeColor : activeColor2}"
                                        style="text-decoration:none;
                                        font-family:Roboto;
                                        font-size: 16px;
                                        color:#FFFFFF;
                                        padding-top:5px;
                                        padding-bottom:5px;
                                        padding-left:55px;
                                        padding-right:55px;
                                        background-color:#FEB72B;
                                        border: 0px solid;
                                        border-width: 3px;border-radius:35px;
                                        float:left;"
                                        v-on:click="generateDocumentSaving()"/>
                                    </p>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
<!-- <button v-on:click="changeIcoPassword()"><img id="imageHidePasswordConfirm" src="../assets/visibility-off.png" width="20" height="20"></button> -->


    <!--<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css">-->
    <!-- <link href="https://unpkg.com/tailwindcss@^1.0/dist/tailwind.min.css" rel="stylesheet"> -->
    <link href='https://fonts.googleapis.com/css?family=Roboto' rel='stylesheet'>
  </div>
</template>


<script>
import('@/assets/css/stylesheet.css')
import MenuLateral from "@/components/Cuenta/MenuLateral.vue";
import Menu from "@/components/Menu.vue";
import Loading from "@/components/Loading/VueLoading.vue";
import Swal from "sweetalert2";
import {saveRequest} from "@/api/user";
import moment from "moment";
import Modal from '@/components/Modal/Modal.vue'
import {getTokenDecoden} from "@/helpers/tokenauth";

export default {
  data() {
    return {
      activeColor: "1",
      activeColor2: "0.4",
      acceptConditions: null,
      rfc: "",
      paternal: "",
      maternal: "",
      fisrt_name: "",
      last_name: "",
      cellphone: "",
      email: "",
      state: "",
      city: "",
      password: "",
      typeReference: "",
      account_type: "",
      multiApi: process.env.VUE_APP_MULTIAPI,
      ccapi: process.env.VUE_APP_CCAPI,
      isLoading: false,
      member_id: "",
      listBeneficiaries: [],
      emailUser: "",
      birthday: "",
      json: null,
      user_rol: null,
      isFinishRegister:false,
      url:null,
      fullname: "",
      ahorroMonto: "",
      admission_id: "",
      catalog_name:"",
      //enrollment_fee_method: "",
      showSuccessModal: false,
      timeIntervalFinished: false,
      startTime: null,
      idInterval: 0,
    };
  },
  name: "TerminosYCondiciones",
  components: {
    MenuLateral,
    Menu,
    Loading,
    Modal,
  },
  mounted() {
  },
  watch: {
    acceptConditions() {
      if (this.acceptConditions == "0") {
        Swal.fire({
          title: "Aviso",
          text:
            "Para concluir con el registro es necesario que acepte y lea los términos y condiciones del aviso de privacidad",
          icon: "warning",
          confirmButtonColor: '#FEB72B',
        });
      }
    },
  },
  async beforeMount() {
    await this.loadInfo();
  },
  methods: {
    setPersonalInfoToLocalStorage(user_id, jsonPersonalInfo) {
      sessionStorage.setItem(user_id, JSON.stringify(jsonPersonalInfo));
    },
    getJsonFromLocalStorageBy(user_id) {
      return JSON.parse(window.sessionStorage.getItem(user_id));
    },
    async generateDocumentSaving() {
      this.isLoading = true;
      let json = this.getJsonFromLocalStorageBy(`admissionUser${this.user_id}`);

      if(Object.entries(json).length == 0 || this.rfc == "" || this.fullname == "" || this.ahorroMonto == "" || this.admission_id == ""){
        this.isLoading = false;
        Swal.fire({
          title: "Aviso",
          text:
              "Se presentó un error. No fue posible recuperar los datos registrados de la solicitud.",
          icon: "error",
          confirmButtonColor: '#FEB72B',
        });
        // window.location.href = "/cuenta/principal";
        this.$router.push({path:"/cuenta/principal"});
      }
      
      let geolocalizacion = (this.getJsonFromLocalStorageBy("geolocalizacion") != null)? this.getJsonFromLocalStorageBy("geolocalizacion") : json.data.response.admission_request.admission_information.geolocation;

      var numberOfContribution = json.data.response.admission_request.admission_information.saving_registration_attributes.number_of_contribution
      var paymentFrecuency = json.data.response.admission_request.admission_information.saving_registration_attributes.payment_frecuency
      var dateOfEnd = json.data.response.admission_request.admission_information.saving_registration_attributes.date_of_end
      var paymentMethod = json.data.response.admission_request.admission_information.saving_registration_attributes.payment_method

      var accountNumber = json.data.response.admission_request.admission_information.bank_account_attributes[0].account_number
      var bankName = json.data.response.admission_request.admission_information.bank_account_attributes[0].bank_name
      var expirationMonth = json.data.response.admission_request.admission_information.bank_account_attributes[0].expiration_month
      var expirationYear = json.data.response.admission_request.admission_information.bank_account_attributes[0].expiration_year
      
      var numberAccount = ""
      var numberCard = ""
      if(expirationMonth != "" && expirationYear != ""){
        numberCard = accountNumber
      }else{
        numberAccount = accountNumber
      }

      let data = {}
      if(paymentMethod == "DOMICILIACIÓN"){
        data = {
          token_auth: sessionStorage.getItem("login"),
          rfc: this.rfc,
          admission_request_id: this.admission_id,
          conducto_pago: "DOMICILIACION",
          sheets: {
            1: {
              dia: moment().format('D').length == 1 ? "0"+moment().format('D') : moment().format('D'),
              mes: moment().format('M').length == 1 ? "0"+moment().format('M') : moment().format('M'),
              anio: moment().format('YYYY'),
              geolocalizacion: geolocalizacion,
              nombre: json.data.response.admission_request.admission_information.name.concat(" ",json.data.response.admission_request.admission_information.middle_name),
              paterno: json.data.response.admission_request.admission_information.paternal_name,
              materno: json.data.response.admission_request.admission_information.maternal_name,
              nombre_solicitante: this.fullname,
              rfc: this.rfc,
              ahorro_monto_numero: (parseFloat(this.ahorroMonto)).toString(),
              nombre_banco: bankName,
              numero_tarjeta: numberCard,
              cuenta_banco: numberAccount,
              plazo_indefinido: numberOfContribution == "0" ? "x" : ".",
              fecha_final: numberOfContribution == "0" ? "." : dateOfEnd
            }
          }
        }
      }
      else
      {
        data = {
          token_auth: sessionStorage.getItem("login"),
          rfc: this.rfc,
          admission_request_id: this.admission_id,
          conducto_pago: paymentMethod,
          sheets: {
            1: {
              fecha: moment().format("DD/MM/YYYY"),
              geolocalizacion: geolocalizacion,
              nombre_solicitante: this.fullname,
              rfc: this.rfc,
              ahorro_monto_numero:this.ahorroMonto,
              prestamo_monto_numero:".",
              prestamo_numero_quincenas:".",
              //pago_parte_social_descripcion: this.enrollment_fee_method == "banco" ? "PAGO EN BANCOS (ANEXAR FICHA DE DEPÓSITO BANCARIO)" : "TRANSFERENCIA",
              frecuencia_pago: paymentFrecuency,
              nombre_banco: bankName,
              numero_tarjeta: numberCard,
              cuenta_banco: numberAccount,
              ahorro_numero_quincenas: numberOfContribution == "0" ? "INDEFINIDO" : numberOfContribution,
              fecha_final: dateOfEnd
            }
          }
        }
      }

      await this.axios
        .post(this.ccapi + `/${process.env.VUE_APP_BUSINESS_FONCABSA}/generate_discount_authorize`, data)
        .then((response) => {
          if (response.data.response.generado == true){
            this.url = response.data.response.url;
            var document_name = response.data.response.nombre_archivo
            this.sentDocumentForSing(document_name);
          } else {
            this.saveRequest();
          }
        })
        .catch((error) => {
          this.isLoading = false;
          console.log("Se presentó un error al generar el autorizo: ", error);
          Swal.fire({
            title: "Aviso",
            text:
                "Se presentó un error. No fue posible generar el autorizo de descuento. Consulte un asesor.",
            icon: "error",
            confirmButtonColor: '#FEB72B',
          });
          this.isFinishRegister = false;
        }).finally(() => {  });
    },
    async sentDocumentForSing(document_name){
      var name_document = document_name;//this.getNameDocumentAuthorize();
      this.isLoading = true;
      let data = {
        document_owner_user_id: this.user_id,
        token_auth: sessionStorage.getItem("login"),
        url_document: this.url,
        document_type: this.catalog_name,
        business: "FONCABSA",
        name_document: name_document,
        description: "Solicitud de autorizo de descuento",
        cincel_request_table_type: "admission_requests",
        cincel_request_table_id: this.admission_id,
        abbreviation: "AUDSCTOAHORRO",
        invitations:[
          {
            name: this.fullname,
            email: this.emailUser,
            message:"Favor de firmar."
          }
        ]
      };
      await this.axios
          .post(this.ccapi + `/${process.env.VUE_APP_BUSINESS_FONCABSA}/send_document`, data)
          .then((response) => {
            this.saveRequest();
          })
          .catch((error) => {
            console.log("Se presentó un error al enviar el documento: ", error);
            this.isFinishRegister = false;
            this.isLoading = false;
            this.showMessage();
          }).finally(() => {  });
    },
    async saveRequest(){
      this.isLoading=true;
      var infoJson = this.buildJson();
      await saveRequest(infoJson).then((response) => {
        this.isFinishRegister = true;
        this.setPersonalInfoToLocalStorage(`admissionUser${this.user_id}`, response);
      })
      .catch((error) => {
        console.log("Se presento un error al actualizar el estatus de la solicitud: ", error);
        this.isFinishRegister = false;
      }).finally( () => { 
        this.showMessage(); 
      })
    },
    buildJson() {
      let json = this.getJsonFromLocalStorageBy(`admissionUser${this.user_id}`);
      
      return {
        token_auth: sessionStorage.getItem("login"),
        admission_request_id: this.admission_id,
        user_id: this.user_id,
        change_for: "terms_and_Conditions",
        admission_request_params: {
          admission_information: json.data.response.admission_request.admission_information,
        },
      };
    },
    async showMessage(){
      this.isLoading=false;
      if(this.isFinishRegister){
        this.showSuccessModal = true;
        this.startCounting();
      }else{
        await Swal.fire({
          title: "Aviso",
          text: "Se presentó un error. No fue posible terminar el registro.",
          icon: "error",
          confirmButtonColor: '#FEB72B',
        });
      }
    },
    changePercentage(value) {
      if (value.includes(".")) {
        value = parseFloat(value);
      }

      var value = parseInt(value);
      if (value < 1 || value > 100) {
        value = 0;
      }
      return value;
    },
    establisDateMaxToday() {
      var today = new Date();
      var dd = today.getDate();
      var mm = today.getMonth() + 1; //January is 0!
      var yyyy = today.getFullYear();
      if (dd < 10) {
        dd = "0" + dd;
      }
      if (mm < 10) {
        mm = "0" + mm;
      }

      today = yyyy + "-" + mm + "-" + dd;
      document.getElementById("birthDate").setAttribute("max", today);
    },
    formatMoney(value) {
      const options2 = { style: "currency", currency: "MXN" };
      const formatter = new Intl.NumberFormat("en-US", options2);
      var resultado = formatter.format(value);
      resultado = resultado.replace("MX", "");
      return resultado;
    },
    formatDate(date) {
      var d = new Date(date);
      var month = "" + (d.getMonth() + 1);
      var day = "" + d.getDate();
      var year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [day, month, year].join("/");
    },
    addUpperCase(value) {
      if (value != "") {
        value = value.toUpperCase();
      }
      return value;
    },
    addLowerCase(value) {
      if (value != "") {
        value = value.toLowerCase();
      }
      return value;
    },
    orderDateByDayMonthYear(date, typeSplit) {
      var elemtsOfDate = date.split(typeSplit);
      var year = elemtsOfDate[0];
      var month = elemtsOfDate[1];
      var day = elemtsOfDate[2];
      return day + "/" + month + "/" + year;
    },
    calculateNumberEntryTwoDates(date) {
      var dateToday = new Date();
      var dateAdminision = new Date(date);

      var antiguedadd = dateToday.getFullYear() - dateAdminision.getFullYear();

      dateAdminision.setFullYear(dateAdminision.getFullYear() + antiguedadd);

      if (dateAdminision > dateToday) {
        antiguedadd--;
      }
      return antiguedadd;
    },
    async encriptarMensaje(mensaje) {
      const header = { alg: "HS256", typ: "JWT" };
      const encodedHeader = Buffer.from(JSON.stringify(header)).toString(
        "base64"
      );

      var data = {
        html: mensaje,
      };
      const encodedPayload = Buffer.from(JSON.stringify(data)).toString(
        "base64"
      );
      var jwtSecret = "vM22%!SmnjNV";
      const crypto = require("crypto");
      const signature = crypto
        .createHmac("sha256", jwtSecret)
        .update(encodedHeader + "." + encodedPayload)
        .digest("base64");
      var tokenMessage = encodedHeader + "." + encodedPayload + "." + signature;
      return tokenMessage;
    },
    async loadInfo() {
      var auth = getTokenDecoden();
      this.emailUser = auth.obj["email"];
      this.user_id = auth["id"];
      this.user_rol =  auth.obj["role"];
      let json = this.getJsonFromLocalStorageBy(`admissionUser${this.user_id}`);
      this.rfc = json.data.response.admission_request.admission_information.rfc;
      this.ahorroMonto = json.data.response.admission_request.admission_information.saving_registration_attributes.amount;
      this.fullname = json.data.response.admission_request.admission_information.name.concat(" ",json.data.response.admission_request.admission_information.middle_name);
      this.fullname = this.fullname.concat(" ",json.data.response.admission_request.admission_information.paternal_name);
      this.fullname = this.fullname.concat(" ",json.data.response.admission_request.admission_information.maternal_name);
      this.admission_id =  json.data.response.admission_request.id;
      console.log("user_rol: ",this.user_rol);
      this.catalog_name = "documentos_alta_socios"
      console.log("catalog_name: ",this.catalog_name);

    },
    getNameDocumentAuthorize(){
      var d = new Date()
      var format_date = d.getFullYear()+("0"+(d.getMonth()+1)).slice(-2)+("0" + d.getDate()).slice(-2) +"_"+ ("0" + d.getHours()).slice(-2)+("0" + d.getMinutes()).slice(-2)+("0" + d.getSeconds()).slice(-2)
      return this.rfc+"_AUDSCTOAHORRO_"+format_date
    },
    startCounting() {
      this.startTime = Date.now();
      this.idInterval = setInterval(this.refresh, 500);
    },
    refresh() {
      let seconds = this.getSeconds();
      if (seconds >= 7) {
        this.timeIntervalFinished = true;
        clearInterval(this.idInterval);
        return;
      }
    },
    getSeconds() {
      return (Date.now() - this.startTime) / 1000;
    },
    reedirect() {
      this.$router.push({path:"/cuenta/principal"});
      // window.location.href ="/cuenta/principal";
      //window.location.href ="/cuenta/pago-parte-social/pago-suscripcion";
    }
  },
};
</script>

<style>

.center {
  padding: 00px 0;
  border: 3px solid #ffffff;
  text-align: center;
}

@media (min-width: 766px) {
  .collapse.dont-collapse-sm {
    display: block;
    height: auto !important;
    visibility: visible;
  }
}

#button_popups {
  border-top-left-radius: 7px !important;
  border-top-right-radius: 7px !important;
  border-bottom-left-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
  background-color: #feb72b !important;
  color: #fff !important;
  font-weight:bold !important;
  width: 250px !important;
  height: 40px !important;
  padding-top: 0% !important;
  font-size:16px !important;
}
@media (max-width: 500px) {
  #button_popups {
    height: 50px !important;
  }
}

button {
  outline: none !important;
}
</style>